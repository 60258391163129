import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LogIn } from '../../redux/reducers/auth/authActions';

import TopNav from '../Navigation/TopNav';

import './SignIn.scss';

const SignIn = () => {
	const currentState = useSelector((state) => state.Auth);

	const [user, setUser] = useState({
		email: '',
		password: ''
	});
	const dispatch = useDispatch();

	const userLogin = (credentials) => dispatch(LogIn(credentials));

	const handleChange = (e) => {
		setUser({
			...user,
			[e.target.name]: e.target.value
		});
	};
	const submitUser = (e) => {
		e.preventDefault();
		userLogin({
			email: user.email,
			password: user.password
		});
	};

	if (currentState.isAuthenticated) {
		return <Redirect to='/home' />;
	}

	return (
		<React.Fragment>
			<TopNav />
			<div className='signin'>
				<form className='signin__form' onSubmit={submitUser}>
					<div className={'signin__form--group'}>
						<label htmlFor=''>Email *</label>
						<input
							type='email'
							placeholder='Your Email Address'
							name='email'
							required
							onChange={handleChange}
						/>
					</div>
					<div className={'signin__form--group'}>
						<label htmlFor=''>Password *</label>
						<input
							type='password'
							placeholder='Your Password'
							name='password'
							required
							onChange={handleChange}
						/>
					</div>
					{currentState.authError ? (
						<div className={'signin__form--group'}>
							<div className='u-margin-top-small form-alert--div'>
								<span className='form-alert--message'>
									{currentState.loginError}
								</span>
							</div>
						</div>
					) : null}
					{currentState.isLoading ? (
						<div className={'signin__form--group'}>
							<button
								className={'btn btn--cta u-margin-top-small'}
								type='submit'
								block='true'
								disabled>
								Signing In ...
							</button>
						</div>
					) : (
						<div className={'signin__form--group'}>
							<button
								className={'btn btn--cta u-margin-top-small'}
								type='submit'
								block='true'
								disabled={
									user.email === '' || user.password === ''
								}>
								Sign In
							</button>
						</div>
					)}
				</form>
			</div>
		</React.Fragment>
	);
};
export default SignIn;
