export const CREATE_BLOG_SUCCESS = 'CREATE_BLOG_SUCCESS';
export const CREATE_BLOG_ERROR = 'CREATE_BLOG_ERROR';
export const FETCH_BLOGS = 'FETCH_BLOGS';
export const FETCH_BLOGS_ERROR = 'FETCH_BLOGS_ERROR';
export const BEFORE_STATE_BLOG = 'BEFORE_STATE_BLOG';
export const SINGLE_BLOG_SUCCESS = 'SINGLE_BLOG_SUCCESS';
export const GET_BLOG_SUCCESS = 'GET_BLOG_SUCCESS';
export const GET_BLOG_ERROR = 'GET_BLOG_ERROR';
export const UPDATE_BLOG_SUCCESS = 'UPDATE_BLOG_SUCCESS';
export const UPDATE_BLOG_ERROR = 'UPDATE_BLOG_ERROR';
export const DELETE_BLOG_SUCCESS = 'DELETE_BLOG_SUCCESS';
export const DELETE_BLOG_ERROR = 'DELETE_BLOG_ERROR';
export const FETCH_AUTH_BLOGS = 'FETCH_AUTH_BLOGS';
export const FETCH_AUTH_BLOGS_ERROR = 'FETCH_AUTH_BLOGS_ERROR';
