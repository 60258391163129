import {
	BEFORE_STATE_BLOG,
	FETCH_BLOGS,
	FETCH_BLOGS_ERROR,
	CREATE_BLOG_SUCCESS,
	UPDATE_BLOG_SUCCESS,
	CREATE_BLOG_ERROR,
	UPDATE_BLOG_ERROR,
	GET_BLOG_SUCCESS,
	GET_BLOG_ERROR,
	DELETE_BLOG_SUCCESS,
	DELETE_BLOG_ERROR,
	FETCH_AUTH_BLOGS,
	FETCH_AUTH_BLOGS_ERROR
} from './blogTypes';

export const initState = {
	blogs: [],
	authblogs: [],
	blog: {},
	blogsError: null,
	isLoading: false
};

const blogReducer = (state = initState, action) => {
	const { payload, type } = action;
	switch (type) {
		case BEFORE_STATE_BLOG:
			return {
				...state,
				blogsError: null,
				isLoading: true
			};
		case FETCH_BLOGS:
			return {
				...state,
				blogs: payload,
				isLoading: false
			};

		case FETCH_BLOGS_ERROR:
			return {
				...state,
				blogsError: payload,
				isLoading: false
			};

		case FETCH_AUTH_BLOGS:
			return {
				...state,
				authPosts: payload,
				isLoading: false
			};

		case FETCH_AUTH_BLOGS_ERROR:
			return {
				...state,
				blogsError: payload,
				isLoading: false
			};

		case GET_BLOG_SUCCESS:
			return {
				...state,
				blog: payload,
				blogsError: null,
				isLoading: false
			};

		case GET_BLOG_ERROR:
			return {
				...state,
				blogsError: payload,
				isLoading: false
			};

		case CREATE_BLOG_SUCCESS:
			return {
				...state,
				blogs: [payload, ...state.blogs],
				blogsError: null,
				isLoading: false
			};

		case CREATE_BLOG_ERROR:
			return {
				...state,
				blogsError: payload,
				isLoading: false
			};

		case UPDATE_BLOG_SUCCESS:
			return {
				...state,
				blogs: state.blogs.map((blog) =>
					blog.id === payload.id
						? {
								...blog,
								title: payload.title,
								content: payload.content
						  }
						: blog
				),
				authBlogs: state.authBlogs.map((blog) =>
					blog.id === payload.id
						? {
								...blog,
								title: payload.title,
								content: payload.content
						  }
						: blog
				),
				blog: payload,
				blogsError: null,
				isLoading: false
			};

		case UPDATE_BLOG_ERROR:
			return {
				...state,
				blogsError: payload,
				isLoading: false
			};

		case DELETE_BLOG_SUCCESS:
			return {
				...state,
				blogs: state.blogd.filter(
					(blog) => blog.id !== payload.deletedID
				),
				authBlogs: state.authBlogs.filter(
					(blog) => blog.id !== payload.deletedID
				),
				blogsError: null,
				isLoading: false
			};

		case DELETE_BLOG_ERROR:
			return {
				...state,
				blogsError: payload,
				isLoading: false
			};

		default:
			return state;
	}
};

export default blogReducer;
