import React from 'react';
import { Link } from 'react-router-dom';

import API_ROUTE from '../../apiRoute';
import WEB_ROUTE from '../../webRoute';

import sprite from '../../assets/icons/sprite.svg';

// import blog_image from '../../assets/img/env.jpg';

function PostCard(props) {
	let {
		blog_id,
		blog_title,
		blog_preview,
		blog_header,
		tags,
		created_at,
		modified_at
	} = props.blog;

	let tag = '';
	if (tags === undefined || tags.length === 0) {
		tag = 'No author';
	} else {
		tag = tags.map((item, key) => <span key={item}>{item}</span>);
	}

	return (
		<div className='post__card'>
			<figure className='post__card--fig'>
				<Link className='post__card--link'>
					<img
						src={`${API_ROUTE}/media/${blog_header}`}
						alt='Blog Header'
						className='post__card--img'
					/>
				</Link>
			</figure>
			<div className='post__card--content'>
				<div className='post__card--tag'>{tag}</div>
				{/* <Link className='post__card--link'> */}
				<h4 className='post__card--title'>{blog_title}</h4>
				{/* </Link> */}
				<p className='post__card--desc'>{blog_preview}</p>
			</div>
			<div className='post__card--action'>
				<button className='btn btn--icon'>
					<svg className='btn--icon-shape'>
						<use xlinkHref={`${sprite}#${'icon-pencil'}`} />
					</svg>
					<span>Edit</span>
				</button>
				{/* TODO
                Dispatch Delete Blog Action */}
				<button className='btn btn--icon'>
					<svg className='btn--icon-shape'>
						<use xlinkHref={`${sprite}#${'icon-trash'}`} />
					</svg>
					<span>Delete</span>
				</button>
				<a
					href={`${WEB_ROUTE}/blog/${blog_title.replace(
						/\s+/g,
						'-'
					)}`}
					target='_blank'
					rel='noopener noreferrer'
					className='link-reset'>
					<button className='btn btn--icon'>
						<svg className='btn--icon-shape'>
							<use xlinkHref={`${sprite}#${'icon-chain'}`} />
						</svg>
						<span>View</span>
					</button>
				</a>
			</div>
		</div>
	);
}

export default PostCard;
