import React from 'react';

import './Profile.scss';

import userPhoto from '../../assets/img/user.jpg';
import sprite from '../../assets/icons/sprite.svg';

const Profile = () => {
	return (
		<div className='profile'>
			<h4 className='heading--sub'>Profile</h4>
			<div className='profile__flex u-margin-top-medium'>
				<div className='profile__visual'>
					<figure className='profile__visual--figure'>
						<img
							src={userPhoto}
							alt='User Default'
							className={'profile__visual--image'}
						/>
					</figure>
					<svg role='button' className='profile__visual--icon'>
						<use xlinkHref={`${sprite}#${'icon-pencil-square'}`} />
					</svg>
				</div>
				<div className='profile__content'>
					<h4 className='heading--sub'>Your Personal Information</h4>
					<form className='profile__form'>
						<div className={'profile__form--group'}>
							<label htmlFor=''>Full Name</label>
							<input
								type='text'
								placeholder='Your full name'
								name='full_name'
							/>
						</div>
						<div className={'profile__form--group'}>
							<label htmlFor=''>Email</label>
							<input
								type='email'
								placeholder='Your email address'
								name='email'
							/>
						</div>
						<div className={'profile__form--group'}>
							<label htmlFor=''>Location</label>
							<input
								type='text'
								placeholder='Add your Location'
								name='Location'
							/>
						</div>
						<h6 className='heading--primary u-margin-top-small u-margin-bottom-small'>
							Update Your Password
						</h6>
						<div className={'profile__form--group'}>
							<label htmlFor=''>Old Password</label>
							<input
								type='password'
								placeholder='Enter your Old Password'
								name='old-password'
								required
							/>
						</div>
						<div className='profile__form--flex u-margin-top-small'>
							<div className={'profile__form--group'}>
								<label htmlFor=''>Enter New Password</label>
								<input
									type='password'
									placeholder='New Password'
									name='new-password'
									required
								/>
							</div>
							<div className={'profile__form--group'}>
								<label htmlFor=''>Confirm New Password</label>
								<input
									type='password'
									placeholder='Confirm New Password'
									name='confirm-password'
									required
								/>
							</div>
						</div>
						<div className={'profile__form--group'}>
							<button
								className={
									'btn btn--cta u-margin-top-small u-link-hover'
								}
								block='true'>
								Save Changes
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};
export default Profile;
