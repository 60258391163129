import React, { Profiler } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { ToastProvider } from 'react-toast-notifications';

import './Dashboard.scss';

import TopNav from '../../components/Navigation/TopNav';

import SideNav from '../../components/Navigation/SideNav';
import CreateBlog from '../../components/Publisher/CreateBlog';
import Profile from '../../components/Profile/Profile';

import Home from '../../components/Home/Home';
import Users from '../../components/Users/Users';
import User from '../../components/Users/User';
import Settings from '../../components/Settings/Settings';
import Posts from '../../components/Posts/Posts';
import Help from '../../components/Help/Help';
import Notifications from '../../components/Notifications/Notifications';
import Notification from '../../components/Notifications/Notification';

function Dasboard() {
	const currentState = useSelector((state) => state);
	if (!currentState.Auth.isAuthenticated) {
		return <Redirect to='/signin' />;
	}
	return (
		<React.Fragment>
			<TopNav />
			<div className='dashboard'>
				<SideNav />
				<main className='main'>
					<Switch>
						<Route exact path='/home'>
							<Home />
						</Route>
						<Route exact path='/write'>
							<ToastProvider>
								<CreateBlog />
							</ToastProvider>
						</Route>
						<Route exact path='/settings'>
							<Settings />
						</Route>
						<Route exact path='/posts/:param'>
							<Posts />
						</Route>
						<Route exact path='/profile'>
							<Profile />
						</Route>
						<Route exact path='/users'>
							<Users />
						</Route>
						<Route exact path='/users/:user'>
							<User />
						</Route>
						<Route exact path='/settings'>
							<Settings />
						</Route>
						<Route exact path='/help'>
							<Help />
						</Route>
						<Route exact path='/notifications'>
							<Notifications />
						</Route>
						<Route exact path='/notifications/:notification'>
							<Notification />
						</Route>
					</Switch>
				</main>
			</div>
		</React.Fragment>
	);
}

export default Dasboard;
