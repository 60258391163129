import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { fetchBlogs } from '../../redux/reducers/blog/blogActions';

import PostCard from './PostCard';
import Spinner from '../Spinner/Spinner';

import './Posts.scss';

const Posts = () => {
	const blogSelector = useSelector((state) => state.Blog);
	const dispatch = useDispatch();

	const getPosts = () => dispatch(fetchBlogs());

	useEffect(() => {
		getPosts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// console.log(blogSelector.blogs);

	if (blogSelector.isLoading) {
		return <Spinner />;
	} else {
		let blogs = blogSelector.blogs.map((blog) => {
			return <PostCard key={blog.blog_id} blog={blog} />;
		});

		return (
			<div className='posts'>
				<h1 className='heading--sub u-margin-bottom-small'>
					All Your Posts
				</h1>
				<div className='posts__table'>{blogs}</div>
			</div>
		);
	}
};
export default Posts;
