import React, { useState } from 'react';
import axios from 'axios';

import { useSelector, useDispatch } from 'react-redux';

import { createBlog } from '../../redux/reducers/blog/blogActions';

import { useToasts } from 'react-toast-notifications';

import API_ROUTE from '../../apiRoute';

import './CreateBlog.scss';

import sprite from '../../assets/icons/sprite.svg';

import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import MarkdownIt from 'markdown-it';

import CreatableSelect from 'react-select/creatable';

// Register plugins if required
// MdEditor.use(YOUR_PLUGINS_HERE);

// Initialize a markdown parser
const mdParser = new MarkdownIt(/* Markdown-it options */);

const MOCK_DATA = ` * Write your blog post here\n * It is fun\n *. You are using markdown. Learn markdown in minutes [here](https://www.markdowntutorial.com/) `;

const CreateBlog = () => {
	const currentState = useSelector((state) => state.Blog);

	const dispatch = useDispatch();
	const { addToast } = useToasts();

	const [state, setState] = useState({
		step: 0,
		file: null,
		blogHeader: null,
		title: null,
		body: null,
		preview: null,
		tags: []
	});

	const handleChange = (e) => {
		e.persist();

		setState((state) => ({
			...state,
			[e.target.name]: e.target.value
		}));
	};

	const handleSelectedTags = (tags) => {
		let values = [];
		tags.map((v) => values.push(v.value));

		setState((state) => ({
			...state,
			tags: values
		}));
	};

	const handleNextClick = () => {
		setState((state) => ({
			...state,
			step: state.step + 1
		}));
	};

	const handlePreviousClick = () => {
		setState((state) => ({
			...state,
			step: state.step - 1
		}));
	};

	async function submitBlog(e) {
		e.preventDefault();

		let data = {
			blog_title: state.title,
			blog_preview: state.preview,
			blog_body: state.body,
			blog_header: state.blogHeader,
			tags: state.tags
		};

		dispatch(createBlog(data));

		// FIXME
		// Dispatch fires an asynchronous event
		// The async even does not update the current state immediately on trigger and thats why we have
		// a bug that shows success twice
		setTimeout(popToast, 3000);
		function popToast() {
			currentState.blogsError !== null
				? addToast(`${currentState.blogsError}`, {
						appearance: 'error',
						autoDismiss: true
				  })
				: addToast('Blog Published Succesfuly', {
						appearance: 'success',
						autoDismiss: true
				  });
		}
	}

	function handleEditorChange({ text }) {
		setState((state) => ({
			...state,
			body: text
		}));
	}

	async function handleImageUpload(file, callback) {
		const data = new FormData();
		data.append('file', file);

		try {
			const response = await axios.post(`${API_ROUTE}/media/`, data, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});

			let media_id = response.data.body.media_id;
			const media_url = `${API_ROUTE}/media/${media_id}`;
			callback(media_url);
		} catch (err) {
			addToast('Something went wrong with your upload', {
				appearance: 'error',
				autoDismiss: true
			});
		}
	}

	async function handleBlogHeaderUpload(e) {
		e.preventDefault();
		let thefile = e.target.files[0];

		setState((state) => ({
			...state,
			file: thefile
		}));

		const data = new FormData();
		data.append('file', thefile);
		try {
			const response = await axios.post(`${API_ROUTE}/media/`, data, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});

			let media_id = response.data.body.media_id;
			setState((state) => ({
				...state,
				blogHeader: media_id
			}));
			addToast('Blog Header Uploaded', {
				appearance: 'success',
				autoDismiss: true
			});
		} catch (err) {
			addToast('Something went wrong with your upload', {
				appearance: 'error',
				autoDismiss: true
			});
		}
	}

	const StepOne = (
		<React.Fragment>
			<label>Your Blog Title</label>
			<input
				className={'u-margin-bottom-small'}
				type='text'
				placeholder='Title'
				name='title'
				value={state.title}
				onChange={handleChange}
			/>
			<label>Write Your Blog</label>

			<MdEditor
				value={state.body === null ? MOCK_DATA : state.body}
				renderHTML={(text) => mdParser.render(text)}
				onChange={handleEditorChange}
				onImageUpload={handleImageUpload}
			/>
		</React.Fragment>
	);

	const StepTwo = (
		<React.Fragment>
			<label htmlFor='headerImage' className={'u-margin-top-small'}>
				A picture is worth a thousand words. Upload a Blog Image Header
				to catch attention
			</label>
			<label htmlFor='headerImage' className={'customFileInput'}>
				<svg className='customFileInput--icon'>
					<use xlinkHref={`${sprite}#${'icon-cloud-upload'}`} />
				</svg>
				<span>
					{state.file === null ? `Browse Image ...` : state.file.name}
				</span>
			</label>
			<input
				className={'u-margin-bottom-small fileInput'}
				type='file'
				placeholder='Browse Image ...'
				name='headerImage'
				id='headerImage'
				accept='image/*'
				onChange={handleBlogHeaderUpload}
			/>
			<label>Write a short blog preview that appears on the feed</label>
			<textarea
				className={'u-margin-bottom-small'}
				rows='3'
				name='preview'
				value={state.preview}
				onChange={handleChange}></textarea>
			<label className={'u-margin-bottom-small'}>
				Add Tags so readers know what your story is about
			</label>
			<CreatableSelect isMulti onChange={handleSelectedTags} />
			<div
				className={'u-margin-top-medium'}
				style={{ position: 'relative' }}>
				<button
					disabled={state.blogHeader === null}
					type='submit'
					className={'btn btn--cta u-link-hover'}
					style={{
						position: 'absolute',
						right: '0'
					}}>
					Publish Now
				</button>
			</div>
		</React.Fragment>
	);

	return (
		<div className={'createblog'}>
			<div
				className={'u-margin-bottom-medium'}
				style={{ position: 'relative' }}>
				{state.step === 1 && (
					<button
						onClick={handlePreviousClick}
						className={'btn btn--cta u-link-hover'}
						style={{
							position: 'absolute',
							left: '0'
						}}>
						Previous
					</button>
				)}
				{state.step === 0 && (
					<button
						onClick={handleNextClick}
						className={'btn btn--cta u-link-hover'}
						style={{
							position: 'absolute',
							right: '0'
						}}>
						Next
					</button>
				)}
			</div>
			<form className={'createblog__form'} onSubmit={submitBlog}>
				{state.step === 0 && StepOne}
				{state.step === 1 && StepTwo}
			</form>
		</div>
	);
};
export default CreateBlog;
