import React from 'react';

import './Users.scss';

import userPhoto from '../../assets/img/user.jpg';
import sprite from '../../assets/icons/sprite.svg';

import SearchBar from '../SearchBar/SearchBar';

const Users = () => {
	return (
		<div className='users'>
			<div className='users__flex u-margin-bottom-small'>
				<h4 className='heading--sub'>All Users</h4>
				<SearchBar />
				<div className='users__actions'>
					<span className='user__card--flex-row'>
						<svg className='user__card--icon-info'>
							<use xlinkHref={`${sprite}#${'icon-user-plus'}`} />
						</svg>
						<span className='user__card--info-email'>
							Add New User
						</span>
					</span>
				</div>
			</div>
			<div className='users__table'>
				{/* USER CARD */}
				<div className='user__card'>
					<div className='user__card--flex-row'>
						<div className='user__card--profile'>
							<figure className='user__card--figure'>
								<img
									src={userPhoto}
									alt='User Default'
									className={'user__card--image'}
								/>
							</figure>
							<div className='user__card--info'>
								<div className='user__card--flex-col'>
									<span className='user__card--info-name'>
										Cosmas Developer
									</span>
									<span className='user__card--flex-row'>
										<svg className='user__card--icon-info'>
											<use
												xlinkHref={`${sprite}#${'icon-envelope'}`}
											/>
										</svg>
										<span className='user__card--info-email'>
											developer@wanonmedia.com
										</span>
									</span>
								</div>
							</div>
						</div>
						{/* <div className='user__card--flex-col'>
							<div className='user__card--info'>Some Info</div>
						</div> */}

						<div className='user__card--action'>
							<svg
								role='button'
								className='user__card--icon-action'>
								<use
									xlinkHref={`${sprite}#${'icon-keyboard_arrow_right'}`}
								/>
							</svg>
						</div>
					</div>
				</div>
				{/* USER CARD ENDS */}
				{/* USER CARD */}
				<div className='user__card'>
					<div className='user__card--flex-row'>
						<div className='user__card--profile'>
							<figure className='user__card--figure'>
								<img
									src={userPhoto}
									alt='User Default'
									className={'user__card--image'}
								/>
							</figure>
							<div className='user__card--info'>
								<div className='user__card--flex-col'>
									<span className='user__card--info-name'>
										Cosmas Developer
									</span>
									<span className='user__card--flex-row'>
										<svg className='user__card--icon-info'>
											<use
												xlinkHref={`${sprite}#${'icon-envelope'}`}
											/>
										</svg>
										<span className='user__card--info-email'>
											developer@wanonmedia.com
										</span>
									</span>
								</div>
							</div>
						</div>
						{/* <div className='user__card--flex-col'>
							<div className='user__card--info'>Some Info</div>
						</div> */}

						<div className='user__card--action'>
							<svg
								role='button'
								className='user__card--icon-action'>
								<use
									xlinkHref={`${sprite}#${'icon-keyboard_arrow_right'}`}
								/>
							</svg>
						</div>
					</div>
				</div>
				{/* USER CARD ENDS */} {/* USER CARD */}
				<div className='user__card'>
					<div className='user__card--flex-row'>
						<div className='user__card--profile'>
							<figure className='user__card--figure'>
								<img
									src={userPhoto}
									alt='User Default'
									className={'user__card--image'}
								/>
							</figure>
							<div className='user__card--info'>
								<div className='user__card--flex-col'>
									<span className='user__card--info-name'>
										Cosmas Developer
									</span>
									<span className='user__card--flex-row'>
										<svg className='user__card--icon-info'>
											<use
												xlinkHref={`${sprite}#${'icon-envelope'}`}
											/>
										</svg>
										<span className='user__card--info-email'>
											developer@wanonmedia.com
										</span>
									</span>
								</div>
							</div>
						</div>
						{/* <div className='user__card--flex-col'>
							<div className='user__card--info'>Some Info</div>
						</div> */}

						<div className='user__card--action'>
							<svg
								role='button'
								className='user__card--icon-action'>
								<use
									xlinkHref={`${sprite}#${'icon-keyboard_arrow_right'}`}
								/>
							</svg>
						</div>
					</div>
				</div>
				{/* USER CARD ENDS */} {/* USER CARD */}
				<div className='user__card'>
					<div className='user__card--flex-row'>
						<div className='user__card--profile'>
							<figure className='user__card--figure'>
								<img
									src={userPhoto}
									alt='User Default'
									className={'user__card--image'}
								/>
							</figure>
							<div className='user__card--info'>
								<div className='user__card--flex-col'>
									<span className='user__card--info-name'>
										Cosmas Developer
									</span>
									<span className='user__card--flex-row'>
										<svg className='user__card--icon-info'>
											<use
												xlinkHref={`${sprite}#${'icon-envelope'}`}
											/>
										</svg>
										<span className='user__card--info-email'>
											developer@wanonmedia.com
										</span>
									</span>
								</div>
							</div>
						</div>
						{/* <div className='user__card--flex-col'>
							<div className='user__card--info'>Some Info</div>
						</div> */}

						<div className='user__card--action'>
							<svg
								role='button'
								className='user__card--icon-action'>
								<use
									xlinkHref={`${sprite}#${'icon-keyboard_arrow_right'}`}
								/>
							</svg>
						</div>
					</div>
				</div>
				{/* USER CARD ENDS */} {/* USER CARD */}
				<div className='user__card'>
					<div className='user__card--flex-row'>
						<div className='user__card--profile'>
							<figure className='user__card--figure'>
								<img
									src={userPhoto}
									alt='User Default'
									className={'user__card--image'}
								/>
							</figure>
							<div className='user__card--info'>
								<div className='user__card--flex-col'>
									<span className='user__card--info-name'>
										Cosmas Developer
									</span>
									<span className='user__card--flex-row'>
										<svg className='user__card--icon-info'>
											<use
												xlinkHref={`${sprite}#${'icon-envelope'}`}
											/>
										</svg>
										<span className='user__card--info-email'>
											developer@wanonmedia.com
										</span>
									</span>
								</div>
							</div>
						</div>
						{/* <div className='user__card--flex-col'>
							<div className='user__card--info'>Some Info</div>
						</div> */}

						<div className='user__card--action'>
							<svg
								role='button'
								className='user__card--icon-action'>
								<use
									xlinkHref={`${sprite}#${'icon-keyboard_arrow_right'}`}
								/>
							</svg>
						</div>
					</div>
				</div>
				{/* USER CARD ENDS */} {/* USER CARD */}
				<div className='user__card'>
					<div className='user__card--flex-row'>
						<div className='user__card--profile'>
							<figure className='user__card--figure'>
								<img
									src={userPhoto}
									alt='User Default'
									className={'user__card--image'}
								/>
							</figure>
							<div className='user__card--info'>
								<div className='user__card--flex-col'>
									<span className='user__card--info-name'>
										Cosmas Developer
									</span>
									<span className='user__card--flex-row'>
										<svg className='user__card--icon-info'>
											<use
												xlinkHref={`${sprite}#${'icon-envelope'}`}
											/>
										</svg>
										<span className='user__card--info-email'>
											developer@wanonmedia.com
										</span>
									</span>
								</div>
							</div>
						</div>
						{/* <div className='user__card--flex-col'>
							<div className='user__card--info'>Some Info</div>
						</div> */}

						<div className='user__card--action'>
							<svg
								role='button'
								className='user__card--icon-action'>
								<use
									xlinkHref={`${sprite}#${'icon-keyboard_arrow_right'}`}
								/>
							</svg>
						</div>
					</div>
				</div>
				{/* USER CARD ENDS */} {/* USER CARD */}
				<div className='user__card'>
					<div className='user__card--flex-row'>
						<div className='user__card--profile'>
							<figure className='user__card--figure'>
								<img
									src={userPhoto}
									alt='User Default'
									className={'user__card--image'}
								/>
							</figure>
							<div className='user__card--info'>
								<div className='user__card--flex-col'>
									<span className='user__card--info-name'>
										Cosmas Developer
									</span>
									<span className='user__card--flex-row'>
										<svg className='user__card--icon-info'>
											<use
												xlinkHref={`${sprite}#${'icon-envelope'}`}
											/>
										</svg>
										<span className='user__card--info-email'>
											developer@wanonmedia.com
										</span>
									</span>
								</div>
							</div>
						</div>
						{/* <div className='user__card--flex-col'>
							<div className='user__card--info'>Some Info</div>
						</div> */}

						<div className='user__card--action'>
							<svg
								role='button'
								className='user__card--icon-action'>
								<use
									xlinkHref={`${sprite}#${'icon-keyboard_arrow_right'}`}
								/>
							</svg>
						</div>
					</div>
				</div>
				{/* USER CARD ENDS */} {/* USER CARD */}
				<div className='user__card'>
					<div className='user__card--flex-row'>
						<div className='user__card--profile'>
							<figure className='user__card--figure'>
								<img
									src={userPhoto}
									alt='User Default'
									className={'user__card--image'}
								/>
							</figure>
							<div className='user__card--info'>
								<div className='user__card--flex-col'>
									<span className='user__card--info-name'>
										Cosmas Developer
									</span>
									<span className='user__card--flex-row'>
										<svg className='user__card--icon-info'>
											<use
												xlinkHref={`${sprite}#${'icon-envelope'}`}
											/>
										</svg>
										<span className='user__card--info-email'>
											developer@wanonmedia.com
										</span>
									</span>
								</div>
							</div>
						</div>
						{/* <div className='user__card--flex-col'>
							<div className='user__card--info'>Some Info</div>
						</div> */}

						<div className='user__card--action'>
							<svg
								role='button'
								className='user__card--icon-action'>
								<use
									xlinkHref={`${sprite}#${'icon-keyboard_arrow_right'}`}
								/>
							</svg>
						</div>
					</div>
				</div>
				{/* USER CARD ENDS */} {/* USER CARD */}
				<div className='user__card'>
					<div className='user__card--flex-row'>
						<div className='user__card--profile'>
							<figure className='user__card--figure'>
								<img
									src={userPhoto}
									alt='User Default'
									className={'user__card--image'}
								/>
							</figure>
							<div className='user__card--info'>
								<div className='user__card--flex-col'>
									<span className='user__card--info-name'>
										Cosmas Developer
									</span>
									<span className='user__card--flex-row'>
										<svg className='user__card--icon-info'>
											<use
												xlinkHref={`${sprite}#${'icon-envelope'}`}
											/>
										</svg>
										<span className='user__card--info-email'>
											developer@wanonmedia.com
										</span>
									</span>
								</div>
							</div>
						</div>
						{/* <div className='user__card--flex-col'>
							<div className='user__card--info'>Some Info</div>
						</div> */}

						<div className='user__card--action'>
							<svg
								role='button'
								className='user__card--icon-action'>
								<use
									xlinkHref={`${sprite}#${'icon-keyboard_arrow_right'}`}
								/>
							</svg>
						</div>
					</div>
				</div>
				{/* USER CARD ENDS */} {/* USER CARD */}
				<div className='user__card'>
					<div className='user__card--flex-row'>
						<div className='user__card--profile'>
							<figure className='user__card--figure'>
								<img
									src={userPhoto}
									alt='User Default'
									className={'user__card--image'}
								/>
							</figure>
							<div className='user__card--info'>
								<div className='user__card--flex-col'>
									<span className='user__card--info-name'>
										Cosmas Developer
									</span>
									<span className='user__card--flex-row'>
										<svg className='user__card--icon-info'>
											<use
												xlinkHref={`${sprite}#${'icon-envelope'}`}
											/>
										</svg>
										<span className='user__card--info-email'>
											developer@wanonmedia.com
										</span>
									</span>
								</div>
							</div>
						</div>
						{/* <div className='user__card--flex-col'>
							<div className='user__card--info'>Some Info</div>
						</div> */}

						<div className='user__card--action'>
							<svg
								role='button'
								className='user__card--icon-action'>
								<use
									xlinkHref={`${sprite}#${'icon-keyboard_arrow_right'}`}
								/>
							</svg>
						</div>
					</div>
				</div>
				{/* USER CARD ENDS */}
			</div>
		</div>
	);
};
export default Users;
