import React from 'react';

import { NavLink, Link } from 'react-router-dom';

import sprite from '../../assets/icons/sprite.svg';
import './SideNav.scss';

const SideNav = () => {
	return (
		<React.Fragment>
			<div className='sidenav'>
				<ul className='sidenav__list'>
					<li className='sidenav__list--item'>
						<NavLink
							to='/home'
							className='sidenav__list--link'
							activeClassName='sidenav--active'>
							<svg className='sidenav__list--icon'>
								<use xlinkHref={`${sprite}#${'icon-home'}`} />
							</svg>
							<span>Home</span>
						</NavLink>
					</li>
					<li className='sidenav__list--item'>
						<NavLink
							to={'/write'}
							className='sidenav__list--link'
							activeClassName='sidenav--active'>
							<svg className='sidenav__list--icon'>
								<use
									xlinkHref={`${sprite}#${'icon-clipboard-edit'}`}
								/>
							</svg>
							<span>Write A New Post</span>
						</NavLink>
					</li>
					<li className='sidenav__list--item'>
						<NavLink
							className='sidenav__list--link'
							activeClassName='sidenav--active'
							to={'/posts/current'}>
							<svg className='sidenav__list--icon'>
								<use
									xlinkHref={`${sprite}#${'icon-clipboard-list'}`}
								/>
							</svg>
							<span>View Your Posts</span>
						</NavLink>
					</li>
					<li className='sidenav__list--item'>
						<NavLink
							className='sidenav__list--link'
							activeClassName='sidenav--active'
							to={'/posts/drafts'}>
							{' '}
							<svg className='sidenav__list--icon'>
								<use
									xlinkHref={`${sprite}#${'icon-bookmark'}`}
								/>
							</svg>
							<span>View Your Drafts</span>
						</NavLink>
					</li>
					<li className='sidenav__list--item'>
						<NavLink
							className='sidenav__list--link'
							activeClassName='sidenav--active'
							to={'/users'}>
							{' '}
							<svg className='sidenav__list--icon'>
								<use xlinkHref={`${sprite}#${'icon-group'}`} />
							</svg>
							<span>Manage Users</span>
						</NavLink>
					</li>
					<li className='sidenav__list--item'>
						<NavLink
							className='sidenav__list--link'
							activeClassName='sidenav--active'
							to={'/settings'}>
							{' '}
							<svg className='sidenav__list--icon'>
								<use xlinkHref={`${sprite}#${'icon-cogs'}`} />
							</svg>
							<span>Settings</span>
						</NavLink>
					</li>
				</ul>
			</div>
		</React.Fragment>
	);
};

export default SideNav;
