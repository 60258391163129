import React from 'react';
import './Home.scss';

const Home = () => {
	return (
		<React.Fragment>
			<h1>Dasboard Home</h1>
		</React.Fragment>
	);
};

export default Home;
