import React from 'react';

import TopNav from '../components/Navigation/TopNav';
import Dashboard from './Dashboard/Dashboard';
import SignIn from '../components/Auth/SignIn';

function App() {
	return (
		<React.Fragment>
			<TopNav />
			<SignIn />
			<Dashboard />
		</React.Fragment>
	);
}

export default App;
